<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <h5>{{agreement.term.title}}</h5>
      <div class="card">

        <div class="card-body" v-if="page==='body'"
             style="overflow-y: auto; max-height: 450px;">
          <h5>Terms</h5>
          <hr />
          <div v-html="agreement.term.body"></div>
        </div>

        <div class="card-body" v-if="page==='health'"
             style="overflow-y: auto; max-height: 450px;">
          <h5>
            Health Care Permissions
            <button class="float-right btn btn-sm btn-outline-primary"
                    @click="selectAll('health')">
              Select All
            </button>
          </h5>
          <hr />
          <div class="row mb-3" v-for="(consent, index) in healthConsents">
            <div class="col">
              <div class="form-check">
                <input
                  :id="`consentAcceptChbId${index}`"
                  class="form-check-input"
                  type="checkbox"
                  v-model="consent.accepted"
                />
                <label
                  :for="`consentAcceptChbId${index}`"
                  class="form-check-label consent-checkbox"
                >
                  {{ consent.consent.title }}
                </label>
              </div>
              <p class="text-primary" v-if="consent.accepted && consent.date_accepted">
                You accepted this agreement on {{consent.date_accepted | formatDate}}
              </p>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="page==='education'"
             style="overflow-y: auto; max-height: 450px;">
          <h5>
            Educational Preferences
            <button class="float-right btn btn-sm btn-outline-primary"
                    @click="selectAll('education')">
              Select All
            </button>
          </h5>
          <hr />
          <div class="row mb-3" v-for="(consent, index) in educationConsents">
            <div class="col">
              <div class="form-check">
                <input
                  :id="`consentAcceptChbId${index}`"
                  class="form-check-input"
                  type="checkbox"
                  v-model="consent.accepted"
                />
                <label
                  :for="`consentAcceptChbId${index}`"
                  class="form-check-label consent-checkbox"
                >
                  {{ consent.consent.title }}
                </label>
              </div>
              <p class="text-primary" v-if="consent.accepted && consent.date_accepted">
                You accepted this agreement on {{consent.date_accepted | formatDate}}
              </p>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="page==='marketing'"
             style="overflow-y: auto; max-height: 450px;">
          <h5>
            Marketing Preferences
            <button class="float-right btn btn-sm btn-outline-primary"
                    @click="selectAll('marketing')">
              Select All
            </button>
          </h5>
          <hr />
          <div class="row mb-3" v-for="(consent, index) in marketingConsents">
            <div class="col">
              <div class="form-check">
                <input
                  :id="`consentAcceptChbId${index}`"
                  class="form-check-input"
                  type="checkbox"
                  v-model="consent.accepted"
                />
                <label
                  :for="`consentAcceptChbId${index}`"
                  class="form-check-label consent-checkbox"
                >
                  {{ consent.consent.title }}
                </label>
              </div>
              <p class="text-primary" v-if="consent.accepted && consent.date_accepted">
                You accepted this agreement on {{consent.date_accepted | formatDate}}
              </p>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="page==='other'"
             style="overflow-y: auto; max-height: 450px;">
          <h5>
            Consent
            <button class="float-right btn btn-sm btn-outline-primary"
                    @click="selectAll('other')">
              Select All
            </button>
          </h5>
          <hr />
          <div class="row mb-3" v-for="(consent, index) in otherConsents">
            <div class="col">
              <div class="form-check">
                <input
                  :id="`consentAcceptChbId${index}`"
                  class="form-check-input"
                  type="checkbox"
                  v-model="consent.accepted"
                />
                <label
                  :for="`consentAcceptChbId${index}`"
                  class="form-check-label consent-checkbox"
                >
                  {{ consent.consent.title }}
                </label>
              </div>
              <p class="text-primary" v-if="consent.accepted && consent.date_accepted">
                You accepted this agreement on {{consent.date_accepted | formatDate}}
              </p>
            </div>
          </div>
        </div>

        <div class="card-footer text-right">
          <div v-if="page !== lastPage">
            <button class="btn btn-light mr-2"
                    v-if="page !== 'body'"
                    @click="goBack">
              <i class="fad fa-arrow-left mr-1"></i>
              Back
            </button>
            <button class="btn btn-primary" @click="goForward">
              <i class="fad fa-arrow-right mr-1"></i>
              Next
            </button>
          </div>
          <div v-else>
            <button class="btn btn-light mr-2"
                    v-if="page !== 'body'"
                    @click="goBack">
              <i class="fad fa-arrow-left mr-1"></i>
              Back
            </button>
            <button class="btn btn-primary" @click="saveAgreement">
              <span v-if="!busySaving">
                <i class="fad fa-save mr-1"></i>
                Save
              </span>
              <span v-else>
                <i class="fad fa-spin fa-spinner mr-1"></i>
                Saving...
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      busy: true,
      busySaving: false,
      agreement: null,
      selectedTerm: null,
      page: "body",
    };
  },
  methods: {
    fetchAgreement() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/client/agreements/" + this.$route.params.id)
        .then(({ data }) => {
          this.agreement = data;
          this.busy = false;
        });
    },
    saveAgreement() {
      this.busySaving = true;
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/agreements/" + this.agreement.id, this.agreement)
        .then(({ data }) => {
          this.busySaving = false;
          this.$EventBus.$emit('alert', data);
          this.$router.push('/agreements');
        });
    },
    selectAll(type) {
      let consents = [];
      if (type === 'health'){
        consents = this.healthConsents;
      } else if (type === 'education'){
        consents = this.educationConsents;
      } else if (type === 'marketing'){
        consents = this.marketingConsents;
      } else if (type === 'other'){
        consents = this.otherConsents;
      }

      consents.forEach((element) => {
        element.accepted = true;
      })
    },
    goBack() {
      if (this.page === "other") {
        if (this.marketingConsents.length > 0){
          this.page = 'marketing';
        } else if (this.educationConsents.length > 0){
          this.page = 'education';
        } else if (this.healthConsents.length > 0){
          this.page = 'health';
        } else {
          this.page = 'body';
        }
      } else if (this.page === 'marketing') {
        if (this.educationConsents.length > 0){
          this.page = 'education';
        } else if (this.healthConsents.length > 0){
          this.page = 'health';
        } else {
          this.page = 'body';
        }
      } else if (this.page === 'education') {
        if (this.healthConsents.length > 0){
          this.page = 'health';
        } else {
          this.page = 'body';
        }
      } else if (this.page === 'health') {
        this.page = 'body';
      }
    },
    goForward() {
      if (this.page === "body") {
        if (this.healthConsents.length > 0){
          this.page = 'health';
        } else if (this.educationConsents.length > 0){
          this.page = 'education';
        } else if (this.marketingConsents.length > 0){
          this.page = 'marketing';
        } else if (this.otherConsents.length > 0){
          this.page = 'other'
        }
      } else if (this.page === 'health') {
        if (this.educationConsents.length > 0){
          this.page = 'education';
        } else if (this.marketingConsents.length > 0){
          this.page = 'marketing'
        } else if (this.otherConsents.length > 0){
          this.page = 'other'
        }
      } else if (this.page === 'education') {
        if (this.marketingConsents.length > 0){
          this.page = 'marketing'
        } else if (this.otherConsents.length > 0){
          this.page = 'other'
        }
      } else if (this.page === 'marketing') {
        if (this.otherConsents.length > 0){
          this.page = 'other'
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.user;
    },
    healthConsents() {
      return this.agreement.consents.filter(
        function (c) {
          return c.consent.type === 'health';
        }
      );
    },
    educationConsents() {
      return this.agreement.consents.filter(
        function (c) {
          return c.consent.type === 'education';
        }
      );
    },
    marketingConsents() {
      return this.agreement.consents.filter(
        function (c) {
          return c.consent.type === 'marketing';
        }
      );
    },
    otherConsents() {
      return this.agreement.consents.filter(
        function (c) {
          return c.consent.type === 'other';
        }
      );
    },
    lastPage() {
      if (this.otherConsents.length > 0){
        return 'other';
      } else if (this.marketingConsents.length > 0){
        return 'marketing';
      } else if (this.educationConsents.length > 0){
        return 'education'
      } else if (this.healthConsents.length > 0){
        return 'health'
      }
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  mounted() {
    this.fetchAgreement();
  }
};
</script>

<style>
  .consent-checkbox{
    color: #495a5a !important;
  }
</style>
